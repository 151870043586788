import React from 'react'
import { Flex } from 'reflexbox'
import Hero from 'src/components/Modules/Hero/Hero'
import SEO from 'src/components/SEO/SEO'
import Components from 'src/storyblok/Components'
import SbEditable from 'storyblok-react'
import * as styles from './PageContent.module.scss'
import { Props } from './types'

const PageContent = ({ blok }: Props) => {
  const { seo, content } = blok
  const { seo_title, seo_description, seo_image }: any =
    seo && seo.length > 0 ? seo[0] : {}

  return (
    <SbEditable content={blok}>
      <Flex className={styles.wrapper} flexDirection="column" width={1}>
        <SEO
          title={seo_title}
          metaDescription={seo_description}
          image={seo_image}
        />

        {content &&
          content.map((blok: any) =>
            React.createElement(Components(blok.component), {
              key: blok._uid,
              blok: blok,
            })
          )}
      </Flex>
    </SbEditable>
  )
}

export default PageContent
