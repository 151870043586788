import React, { useEffect, useRef } from 'react'
import { Box, Flex } from 'reflexbox'
import SbEditable from 'storyblok-react'

import Heading from 'src/components/Heading/Heading'
import Markdown from 'src/components/Markdown/Markdown'
import Image from 'src/components/UI/Image/Image'
import { getColor } from 'src/helpers/getColor'
import Components from 'src/storyblok/Components'

import * as styles from './TextAndFiles.module.scss'
import { Props } from './types'
import { Link } from 'gatsby'

const TextAndFiles = ({ blok }: any) => {
  const { title, files_heading, preamble, background, files, anchor } = blok
  const color = getColor(background)
  return (
    <SbEditable content={blok}>
      <section className={styles.wrapper} textcolor="black" bgcolor={color}>
        <Flex
          // minHeight="100vh"
          paddingTop={[10, null, null, '128px']}
          paddingBottom={[8, null, null, 20]}
          paddingX={[7, null, null, 16]}
          flexDirection="column"
        >
          <Flex width={1} flexDirection="column" minHeight="50vh">
            <Box>
              <div
                id={anchor}
                style={{ position: 'relative', top: '-128px' }}
              />
              <Heading title={title} preamble={preamble} />
            </Box>
            <Flex
              marginTop={[8, null, null, 12]}
              justifyContent="flex-end"
              width={1}
              paddingRight={[0, null, null, `${(1 / 12) * 100}%`]}
            >
              <Flex flexDirection={'column'} width={[1, null, null, 7 / 12]}>
                <h3 className={styles.heading}>{files_heading}</h3>
                {files &&
                  files.map((file: any) => (
                    <Link
                      href={file.file.filename}
                      target="_blank"
                      className={styles.row}
                    >
                      <svg
                        className={styles.icon}
                        width="14"
                        height="20"
                        viewBox="0 0 14 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M5.79741 0L0 6.65451V20H14V0H5.79741ZM5.41167 3.02123V6.1284H2.7047L5.41167 3.02123ZM12.4117 18.1768H1.58833V7.95156H7V1.82315H12.4117V18.1768Z"
                          fill="black"
                        />
                      </svg>

                      <span>{file.title}</span>
                    </Link>
                  ))}
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </section>
    </SbEditable>
  )
}

export default TextAndFiles
