import React from 'react'
import { Box, Flex } from 'reflexbox'
import Image from 'src/components/UI/Image/Image'
import SbEditable from 'storyblok-react'
import * as styles from './Hero.module.scss'
import { Props } from './types'
import scrollIntoView from 'scroll-into-view'
import { Link } from 'gatsby'

const Hero = ({ blok, title, cta, image, anchor }: Props) => {
  return (
    <SbEditable content={blok}>
      <Box id="hero" className={styles.wrapper} width={1}>
        <Flex
          paddingBottom={[16, null, null, 32]}
          paddingX={[7, null, null, 16]}
          className={styles.content}
          width={1}
          height="100%"
          alignItems="center"
        >
          <Flex
            marginLeft={[0, null, null, `${(1 / 12) * 100}%`]}
            width={[1, null, null, 8 / 12]}
            height="100%"
            justifyContent={['flex-end', null, null, 'center']}
            alignItems="flex-start"
            flexDirection="column"
          >
            <Box marginBottom={['42px', null, null, '42px']} as="h1">
              {title}
            </Box>
            {cta && cta[0] && cta[0].link && (
              <Link to={cta[0].link.cached_url} className={'button'}>
                {cta[0].title}
                <svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.95466 0L6.91406 1.03474L13.1829 7.26829H0L9.97536e-08 8.73171H13.1829L6.91406 14.9653L7.95466 16L16 8L7.95466 0Z"
                    fill="black"
                  />
                </svg>
              </Link>
            )}
          </Flex>
        </Flex>
        <Image src={image} size="100vw" alt="title" />
      </Box>
    </SbEditable>
  )
}

export default Hero
