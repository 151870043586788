// Page types
import PageStart from './Pages/PageStart/PageStart'

// Global Modules
import Header from './GlobalModules/Header/Header'
import Footer from './GlobalModules/Footer/Footer'

// Components
import RealEstate from './Components/RealEstate/RealEstate'
import InfoRow from './Components/InfoRow/InfoRow'
import ContactForm from './Components/ContactForm/ContactForm'

// Modules
import TextWithInfoList from './Modules/TextWithInfoList/TextWithInfoList'
import ImageAndText from './Modules/ImageAndText/ImageAndText'
import ComponentNotFound from './ComponentNotFound'
import PageContent from './Pages/PageContent/PageContent'
import TextAndFiles from './Modules/TextAndFiles/TextAndFiles'

const ComponentList: any = {
  // Page types
  page_start: PageStart,
  page_content: PageContent,
  // Global Modules
  header: Header,
  footer: Footer,
  // Components
  real_estate: RealEstate,
  info_row: InfoRow,
  contact_form: ContactForm,

  // Modules
  text_with_info_list: TextWithInfoList,
  image_and_text: ImageAndText,
  text_and_files: TextAndFiles,
}

const Components = (type: string) => {
  if (typeof ComponentList[type] === 'undefined') {
    return ComponentNotFound
  }
  return ComponentList[type]
}

export default Components
